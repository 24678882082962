import * as styles from './filter.module.scss'

import React from 'react'
import { translate } from '../../translations'

const SearchFilter = React.memo(({ locale = "de", filters, setFilters = () => { } }) => {
  const options = [
    {
      name: 'categories',
      type: 'checkbox',
      items: [
        { key: 'breakfast' },
        { key: 'saladsSoup' },
        { key: 'snacks' },
        { key: 'main' },
        { key: 'sweets' }
      ],
      onToggle: (key) => {
        setFilters({
          ...filters,
          categories: toggleKeyInFilter(key, filters.categories)
        })
      }
    },
    {
      name: 'duration',
      type: 'checkbox',
      items: [
        { key: 20 },
        { key: 40 },
        { key: 60 },
        { key: 61 }
      ],
      onToggle: (key) => {
        const duration = toggleRadioKeyInFilter(key, filters.duration)
        setFilters({
          ...filters,
          duration
        })
      }
    },
    {
      name: 'allergens',
      type: 'checkbox',
      items: [
        { key: 'glutenFree' },
        { key: 'soyFree' },
        { key: 'withoutNuts' },
      ],
      onToggle: (key) => {
        setFilters({
          ...filters,
          allergens: toggleKeyInFilter(key, filters.allergens)
        })
      }
    },
    {
      name: 'difficulty',
      type: 'checkbox',
      items: [
        { key: 'easy' },
        { key: 'medium' },
        { key: 'difficult' },
      ],
      onToggle: (key) => {
        setFilters({
          ...filters,
          difficulty: toggleRadioKeyInFilter(key, filters.difficulty)
        })
      }
    }
  ].map(option => <FilterOption key={option.name} {...option} locale={locale} filters={filters} />)

  const toggleKeyInFilter = (key, filters) => {
    if (filters.includes(key)) {
      return filters.filter(item => item !== key)
    } else {
      return [
        ...filters,
        key
      ]
    }
  }

  const toggleRadioKeyInFilter = (key, filters) => {
    if (filters.includes(key)) {
      return []
    } else {
      return [key]
    }
  }

  return (
    <div className={styles.wrapper}>
      {options}
    </div>
  )
})

const FilterOption = ({ name, type, items, onToggle, locale, filters }) => {
  const isSelected = (item) => {
    return filters[name].includes(item.key)
  }

  const optionItems = items.map(item => (
    <div key={item.key} className={styles.item}>
      <label>
        <input key={isSelected(item)} type={type} name={name} defaultChecked={isSelected(item)} onChange={() => onToggle(item.key)} className={styles.input} />
        <span className={styles.checkbox} />
        <span>{translate(`search.filters.${name}.${item.key}`, locale)}</span>
      </label>
    </div>
  ))

  return (
    <div className={styles.filter}>
      <div className={styles.label}>
        {translate(`search.filters.${name}.label`, locale)}
      </div>
      <div className={styles.items}>
        {optionItems}
      </div>
    </div>
  )
}

export default SearchFilter
