export function getField(object, key, locale) {
  try {
    const upperLocale = locale.charAt(0).toUpperCase() + locale.slice(1)
    return object[`${key}${upperLocale}`]
  } catch (e) {
    return undefined
  }
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// module.exports.getField = getField
