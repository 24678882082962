import * as styles from './toggle.module.scss';

import React from "react";

const HeaderToggle = ({ active, onClick }) => {
  return (
    <button className={styles.toggle} data-active={active} onClick={onClick}>
      <div className={styles.bar}/>
      <div className={styles.bar}/>
      <div className={styles.bar}/>
    </button>
  )
};

export default HeaderToggle;
