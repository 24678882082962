import * as styles from './index.module.scss';

import React from "react";

const Container = ({ children }) => {
  return (
    <section className={styles.container}>{children}</section>
  )
};

export default Container;
