import * as styles from './form-small.module.scss'

import React, { useState } from 'react'

import { translate } from '../../translations'
import Button from '../button'
import Input from '../form/input'
import Filter from './filter'
import { buildUrlFromFiltersAndQuery } from './helper'

const SearchFormSmall = ({ locale, id, withBorder = false, showFilter = false }) => {
  const [query, setQuery] = useState(``)
  const [filters, setFilters] = useState({
    allergens: [],
    difficulty: [],
    categories: [],
    duration: []
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const url = new URL(locale === 'de' ? '/de/suche/' : '/en/search/', `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`)

    const fullUrl = buildUrlFromFiltersAndQuery(filters, query, url)
    console.log({ fullUrl })
    // navigate(fullUrl)
    window.location = fullUrl
  }

  return (
    <form id={id} className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.input}>
        <Input onChange={(e) => setQuery(e.target.value)} placeholder={translate('search.section.placeholder', locale)} defaultValue={query} withBorder={withBorder} />
      </div>
      {showFilter && <Filter locale={locale} filters={filters} setFilters={setFilters} />}
      <Button label={translate('search.section.buttonLabel', locale)} onClick={handleSubmit} className={styles.button} />
    </form>
  )
}

export default SearchFormSmall
