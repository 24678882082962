import * as styles from './search.module.scss';

import Container from '../../container';
import React from "react";
import SearchFormSmall from '../../search/form-small';

const HeaderMenuSearch = ({ locale }) => {
  return (
    <div className={styles.search}>
      <Container>
        <SearchFormSmall id="header-searchfield" locale={locale} withBorder={true}/>
      </Container>
    </div>
  )
};

export default HeaderMenuSearch;
