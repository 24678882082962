import * as styles from './index.module.scss';

import HeaderMenuInfoBar from './infoBar';
import HeaderMenuNavigation from './navigation';
import HeaderMenuSearch from './search';
import React from "react";

const HeaderMenu = ({ locale, showMenu, setShowMenu, navigation, socials, localizedUrls }) => {
  return (
    <div className={styles.menu} data-active={showMenu}>
      <div className={styles.content}>
        <HeaderMenuInfoBar locale={locale} socials={socials} localizedUrls={localizedUrls}/>
        <HeaderMenuSearch locale={locale}/>
        <HeaderMenuNavigation navigation={navigation} setShowMenu={setShowMenu} locale={locale}/>
      </div>
    </div>
  )
};

export default HeaderMenu;
