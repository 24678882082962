import * as styles from './renderer.module.scss'

import React from 'react'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'

function TextRenderer({ text='', renderers={} }) {
  const defaultRenderers = { ...renderers }

  return (
    <div className={styles.text}>
      <ReactMarkdown source={text} plugins={[breaks]} renderers={defaultRenderers}/>
    </div>
  )
}

export default TextRenderer
