import * as styles from './index.module.scss'

import { Link } from 'gatsby'
import React from "react"

function Button({ label = 'Please add button text', onClick = () => { }, to, type = 'primary', className = '' }) {
  const Tag = to ? Link : "button"
  return (
    <Tag className={[styles.button, className].join(" ")} to={to} data-type={type} onClick={onClick}>{label}</Tag>
  )
}

export default Button
