import * as styles from './modal.module.scss'

import { graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const PopupModal = ({ open }) => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: {eq: "popup-adventskalender.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 65
            width: 323
          )
        }
      }
    }
  `)

  return (
    <div className={styles.modal} data-open={open}>
      <div className={styles.title}>Unser Adventskalender ist zurück!<span role="img" aria-label="Tada Emoji">🎄</span></div>
      <div className={styles.body}>Wir haben ihm einen neuen Look verpasst und wie immer mit 24 unserer allerliebsten veganen Produkte des Jahres gefüllt. Ab dem 01.09. um 10 Uhr könnt ihr ihn in unserem Shop bestellen.</div>
      <div className={styles.image}>
        <GatsbyImage
          image={image?.childImageSharp?.gatsbyImageData}
          alt="Unser Adventskalender ist zurück!"
          style={{ maxWidth: "100%" }}
        />
      </div>
      <a target='_blank' className={styles.button} href="https://shop.zuckerjagdwurst.com/products/der-zucker-jagdwurst-adventskalender-2024">Alle Infos</a>
    </div>
  )
}

export default PopupModal
