import * as styles from './index.module.scss'

import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect } from "react"

import { Helmet } from 'react-helmet'
import Footer from "../footer"
import Header from "../header"
// import LanguageBanner from '../../components/banner/language'
import smoothscroll from 'smoothscroll-polyfill'
import Popup from '../popup'

const Layout = ({ children, locale = 'de', localizedUrls }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      navigation: cmsNavigationZone(identifier: {eq: "main"}) {
        ...NavigationZone
      }

      socials: cmsNavigationZone(identifier: {eq: "header-socials"}) {
        ...NavigationZone
      }

      quick: cmsNavigationZone(identifier: {eq: "quick-links"}) {
        ...NavigationZone
      }

      footer: cmsNavigationZone(identifier: {eq: "footer"}) {
        ...NavigationZone
      }
    }
  `)
  const { navigation, socials, quick, footer } = data

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  useEffect(() => {
    [...document.links].forEach(link => {
      if (link.hostname !== window.location.hostname) {
        link.target = '_blank'
        link.rel = 'noopener'
      }
    })
  })

  return (
    <>
      <Helmet>
        <meta name="google-site-verification" content="dxklnKQ3agtLXNnaSrTJjHAAMvo4sJ4ybSe8fHhqmP0" />
        <meta name="facebook-domain-verification" content="31i30vosd1go2qq3g9tt7plmqqg9zm" />
        <meta name="locale" content={locale} />
        <style type="text/css">{`
          .gatsby-image-wrapper-constrained {
            display: block !important
          }
        `}</style>
        <script defer data-domain="zuckerjagdwurst.com" src="https://plausible.io/js/script.outbound-links.js" />
      </Helmet>
      <Header
        locale={locale}
        navigation={navigation}
        socials={socials}
        quick={quick}
        localizedUrls={localizedUrls}
      />
      <main className={styles.main}>{children}</main>
      <Footer navigation={footer} locale={locale} />
      <Popup locale={locale} />
    </>
  )
}

export default Layout


// <LanguageBanner locale={locale}/>
