import * as styles from './index.module.scss'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Link, graphql } from 'gatsby'
import React, { useEffect, useState } from "react"

import Headroom from 'react-headroom'
import ClickOutside from '../clickOutside'
import HeaderMenu from './menu'
import HeaderQuickNavigation from './quick'
import HeaderToggle from './toggle'
// import ShoppingCart from '../../shop/components/cart/icon';
import logoDe from '../../assets/images/logo-de.svg'
import logoEn from '../../assets/images/logo-en.svg'
import { pathTo } from '../../routes'

const Header = ({ locale, navigation, socials, quick, localizedUrls }) => {
  const [showMenu, setShowMenu] = useState(false)

  const homePage = {
    __typename: "CMSPage",
    slugDe: 'home',
    slugEn: 'home',
    locale
  }

  useEffect(() => {
    const target = document.querySelector('body')
    if (showMenu) {
      disableBodyScroll(target)
    } else {
      enableBodyScroll(target)
    }
  }, [showMenu])

  const toggleSearch = () => {
    setShowMenu(true)
    setTimeout(function () {
      document.querySelector('#header-searchfield input[type="text"]').focus()
    }, 401)
  }

  const logo = locale === 'de' ? logoDe : logoEn

  return (
    <>
      <header id="header" className={styles.header}>
        <ClickOutside onClick={() => setShowMenu(false)}>
          <div className={styles.top}>
            <div className={styles.search} onClick={toggleSearch} onKeyDown={toggleSearch} role='button' tabIndex={0}>
              <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" />
              </svg>
            </div>
            <div className={styles.logo}>
              <Link to={pathTo(homePage)}>
                <img src={logo} alt="" title="" height="39" width="240" />
              </Link>
            </div>
            <HeaderToggle active={showMenu} onClick={() => setShowMenu(!showMenu)} />
          </div>
          <Headroom pinStart={59} disableInlineStyles>
            <div className={styles.bottom}>
              <HeaderQuickNavigation
                quickNavigation={quick}
                locale={locale}
              />
            </div>
          </Headroom>
          <div>
            <HeaderMenu
              locale={locale}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              navigation={navigation}
              socials={socials}
              localizedUrls={localizedUrls}
            />
          </div>
        </ClickOutside>
      </header>
      {/* <ShoppingCart/> */}
    </>
  )
}

export default Header


export const query = graphql`
  fragment NavigationZoneItem on CMSNavigationZoneItem {
    __typename
    title
    link
    itemsDe {
      title
      link
    }
    itemsEn {
      title
      link
    }
  }
  fragment NavigationZone on CMSNavigationZone {
    id
    __typename
    itemsDe {
      ...NavigationZoneItem
    }
    itemsEn {
      ...NavigationZoneItem
    }
  }
`
