import React, { useEffect, useMemo, useState } from 'react'

import { useLocation } from "@reach/router"
import { useCookies } from 'react-cookie'
import PopupModal from './modal'
import PopupTrigger from './trigger'

const COOKIE_NAME = 'gatsby-zj-popup-pasta-guide'

const Popup = ({ locale }) => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME])
  const hidePopup = cookies[COOKIE_NAME] === undefined || cookies[COOKIE_NAME] === 'false'
  const [open, setOpen] = useState(!hidePopup)
  const location = useLocation()
  const expireAt = useMemo(() => new Date(new Date().setDate(new Date().getDate() + 90)), [])

  const onToggle = () => {
    setOpen(!open)
    if (!open === false) {
      setCookie(COOKIE_NAME, false, { expires: expireAt, path: '/' })
    }
  }

  useEffect(() => {
    if (cookies[COOKIE_NAME] === undefined) {
      setTimeout(function () {
        setOpen(true)
        setCookie(COOKIE_NAME, false, { expires: expireAt, path: '/' })
      }, 1000)
    }
  }, [cookies, expireAt, setCookie])

  if (locale === 'en') return null
  if (location.pathname === "/de/shop/der-zucker-jagdwurst-vegan-guide") return null

  return (
    <>
      <PopupTrigger open={open} onToggle={onToggle} />
      <PopupModal open={open} />
    </>
  )
}

export default Popup
