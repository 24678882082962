import * as styles from './quick.module.scss';

import { Link } from 'gatsby';
import React from "react";
import { getField } from '../../helper.js'

// import { pathTo } from '../../routes';

const HeaderQuickNavigation = ({ quickNavigation, locale }) => {
  const items = [...getField(quickNavigation, 'items', locale)]
  return (
    <nav className={styles.quick}>
      {items.map((item, index) => <Link className={styles.item} key={index} to={item.link}>{item.title}</Link>)}
    </nav>
  )
};

export default HeaderQuickNavigation
