import * as styles from './subNavigation.module.scss'

import { Link } from 'gatsby'
import React from "react"

// import { pathTo } from '../../../routes'

const HeaderMenuSubNavigation = ({ items=[], setShowMenu }) => {
  if (items.length === 0 || items === undefined) return null


  return (
    <nav className={styles.items}>
      {items.map((item, index) => (
        <Link
          key={index}
          className={styles.item}
          activeClassName={styles.active}
          to={item.link}
          onClick={() => setShowMenu(false)}
        >
          <span>{item.title}</span>
        </Link>
      ))}
    </nav>
  )
}

export default HeaderMenuSubNavigation
