export function buildUrlFromFiltersAndQuery(filters, query, baseUrl) {
  if (typeof window === `undefined`) return
  if (baseUrl === undefined) {
    baseUrl = window.location
  }
  // Create a URL object
  const urlObj = new URL(baseUrl)

  // Function to add array parameters to URLSearchParams
  function addArrayParams(params, key, values) {
    values.forEach(value => {
      params.append(key, value)
    })
  }

  // Create URLSearchParams object
  const searchParams = new URLSearchParams()

  // Add filters to searchParams
  for (const key in filters) {
    if (filters[key].length > 0) {
      addArrayParams(searchParams, key, filters[key])
    }
  }

  addArrayParams(searchParams, "query", [query])

  // Add the search parameters to the URL object
  urlObj.search = searchParams.toString()

  // Get the full URL as a string

  return urlObj.toString()
}