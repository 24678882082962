import * as styles from './navigation.module.scss'

import { Link } from 'gatsby'
import React from "react"
import { getField } from '../../../helper.js'
import HeaderMenuSubNavigation from './subNavigation'

// import { pathTo } from '../../../routes';

const HeaderMenuNavigation = ({ navigation, setShowMenu, locale }) => {
  const navigationItems = getField(navigation, 'items', locale) || []

  return (
    <nav className={styles.items}>
      {navigationItems.map((item, index) => (
        <React.Fragment key={index}>
          <Link
            className={styles.item}
            activeClassName={styles.active}
            to={item.link}
            onClick={() => setShowMenu(false)}
          >
            <span>{item.title}</span>
          </Link>
          <HeaderMenuSubNavigation
            items={getField(item, 'items', locale) || []}
            setShowMenu={setShowMenu}
          />
        </React.Fragment>
      ))}
    </nav>
  )
}

export default HeaderMenuNavigation
