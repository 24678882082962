import * as styles from './input.module.scss';

import React from 'react';
import icon from '../../assets/images/search-icon.svg';

const Input = ({ onChange=()=>{}, placeholder=`Please add a placeholder`, defaultValue=``, withBorder=false }) => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.icon} src={icon} alt=""/>
      <input className={styles.input} type="text" placeholder={placeholder} onChange={onChange} defaultValue={defaultValue} data-with-border={withBorder}/>
    </div>
  )
}

export default Input;
