import * as styles from './index.module.scss'

import { Link, graphql, useStaticQuery } from 'gatsby'

import Container from '../container'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"
import TextRenderer from '../text/renderer'
import donut from '../../assets/images/donut.svg'
import { getField } from '../../helper.js'
// import { pathTo } from '../../routes'
import { translate } from '../../translations'

const Footer = ({ navigation, locale }) => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(
        relativePath: {eq: "IsaJulia_ZuckerJagdwurst_Foto_by_Hella_Wittenberg.jpg"}
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 65
            width: 240
          )
        }
      }
    }
  `)

  const navigationItems = [...getField(navigation, 'items', locale)]

  return (
    <>
      <div className={styles.donut}>
        <img src={donut} alt="Zucker &map; Jagdwurst Donut" height="90" width="90"/>
      </div>
      <footer className={styles.footer}>
        <Container>
          <div className={styles.about}>
            {( image?.childImageSharp?.gatsbyImageData &&
              <div className={styles.image}>
                <GatsbyImage
                  image={image?.childImageSharp?.gatsbyImageData}
                  alt={translate('footer.about.headline', locale)}
                />
              </div>
            )}
            <div className={styles.text}>
              <div className={styles.headline}>
                <span>{translate('footer.about.headline', locale)}</span>
              </div>
              <div>
                <TextRenderer text={translate('footer.about.copy', locale)}/>
              </div>
            </div>
          </div>
          <div className={styles.legal}>
            <div>
              <TextRenderer text={translate('footer.legal.copyright', locale)}/>
            </div>
            <div>
              {navigationItems.map((item, index) => (
                <div key={index} className={styles.link}>
                  <Link to={item.link}>
                    <span>{item.title}</span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </footer>
    </>
  )
}

export default Footer
