import * as styles from './ad.module.scss';

import React from "react";
import { translate } from '../../translations';

const AdBadge = ({ locale }) => (
  <div className={styles.pill}>{translate('general.ad', locale)}</div>
)

export default AdBadge;
